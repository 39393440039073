@keyframes showItemContent {
  from {
    transform: perspective(100em) rotateX(90deg);
  }

  to {
    transform: perspective(100em) rotateX(0deg);
    opacity: 1;
  }
}

section.learn-more {
  margin-bottom: 0;

  .item {
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
    @include boxShadow();

    &>img {
      max-width: initial;
      width: 100%;
    }
  }

  .item-content {
    width: 100%;
  }

  .phone-info {
    a {
      font-weight: 900;
      font-size: 1.4rem;
      line-height: 1;
    }

    .fa-phone {
      transform: scaleX(-1);
      font-size: 1.4rem;
    }

    small {
      font-size: 0.75rem;
    }

    .no-wrap {
      white-space: nowrap;
    }
  }

  @include breakpoint(400px) {
    .item-content {
      position: absolute;
      padding: 1rem;
      bottom: 0;
      left: 0;
      padding: 1rem;
      width: 100%;
      opacity: 0;
      transform-origin: bottom;
    }

    .item-1 .item-content {
      animation-name: showItemContent;
      animation-duration: 500ms;
      animation-delay: 100ms;
      animation-fill-mode: forwards;
      animation-play-state: paused;
    }

    .item-2 .item-content {
      animation-name: showItemContent;
      animation-duration: 500ms;
      animation-delay: 300ms;
      animation-fill-mode: forwards;
      animation-play-state: paused;
    }
  }

  .content-inner {
    background: rgba(255, 255, 255, 0.75);
    padding: 1rem;
    font-size: 0.9em;
  }

  h5 {
    color: $mundus-agri-color;
  }

  h5,
  p {
    margin-bottom: 0.5rem
  }

  p {
    line-height: 1.3;
  }

  &.visible {
    .item-content {
      animation-play-state: running;
    }
  }
}