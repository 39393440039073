@import 'settings';
@import '~application/node_modules/foundation-sites/scss/foundation';

.modal-head {
  background-color: $light-gray;

  i {
    color: $primary-color;
  }

  h3 {
    margin-bottom: 0;
  }
}

.modal-footer {
  background-color: $light-gray;
}

.button-join-business-group.tiny {
  padding: 0.15rem 0.25rem;
}

.input-group {

  input,
  select {
    margin-bottom: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

div.mce-tinymce {
  width: auto !important;
  border-color: $medium-gray;
  box-shadow: none;
  border-top-left-radius: $global-radius;
  border-top-right-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
  overflow: hidden;
}

hr.dotted {
  border-style: dotted;
}

.is-bio {
  border-radius: 2px;
  background-color: get-color(success);
  color: #fff;
  padding: 0.1rem 0.25rem;
}

.fa-question-circle[data-tooltip] {
  border: 0;
  font-size: 0.85rem;
}

// uic-single-select START
.uic-single-select-drop {
  border: 1px solid $medium-gray;
  border-radius: $global-radius;
  margin-bottom: rem-calc(16);
}

.uic-single-select-drop.uic-expanded .uic-selection {
  background: transparent;
}

.uic-single-select-drop.uic-expanded .uic-menu {
  border-bottom-right-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
}

// uic-single-select END

/* uic-pretty-[checkbox|radio] START */
.uic-pretty-checkbox+label,
.uic-pretty-radio+label {
  display: inline;
}

// uic-single-select-drop / uic-multi-checkbox START
.uic-multi-checkbox-dropdown {
  border-color: $medium-gray;
  border-radius: $global-radius;
  margin-bottom: rem-calc(16);

  .uic-scrollable {
    @include overflowScrollY();

    label {
      font-weight: normal;
    }
  }
}

.uic-single-select-drop .uic-menu,
.uic-multi-checkbox-dropdown .uic-menu {
  font-size: 0.8rem;
}

.uic-single-select-drop .uic-menu,
.uic-single-select-drop.uic-expanded,
.uic-multi-checkbox-dropdown.uic-expanded,
.uic-multi-checkbox-dropdown[data-uic-dropdown-style=none] {
  border-color: $medium-gray;
}

.uic-multi-checkbox-dropdown.uic-expanded .uic-label,
.uic-multi-checkbox-dropdown[data-uic-dropdown-style=none] .uic-label {
  background: transparent;
}

.uic-multi-checkbox-dropdown.uic-expanded .uic-menu,
.uic-multi-checkbox-dropdown[data-uic-dropdown-style=none] .uic-menu {
  border-bottom-right-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
  border-color: $medium-gray;
}

// uic-multi-checkbox END

// Tabs START
.tabs-container .tab-label {
  font-size: 0.9rem;
}

// Tabs END

#dedere-header .dh-header-inner {
  @include xy-grid-container;
}

.button,
button {
  outline: 0;

  &.hollow {
    background-color: #fff;
  }
}

.button.dedere {
  @include button-style($dedere-color, scale-color($dedere-color, $lightness:-15%), #fff);
}

svg.icon {
  fill: none;
  stroke: $mundus-agri-color;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.uppercase {
  text-transform: uppercase;
}

.alert {
  color: get-color(alert);
}

a {
  &.alert {
    color: get-color(alert);
    transition: color 0.25s ease-out;

    &:hover {
      color: scale-color(get-color(alert), $lightness: -15%);
    }
  }
}

// Datepicker START
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: $highlight-color;
  border-color: $highlight-color;
}

// Datepicker END

// Update Membership Modal START
.modal-window.upgrade-membership-modal {
  align-items: center;

  &.modal-open {
    display: flex;
  }

  .modal-inner {
    max-width: 600px;
    // padding:1rem;
  }

  .modal-content {
    // max-width:600px;
    padding: 1rem;
  }
}

// Update Membership Modal END