.component.harvesting-dates {
  .sub-headline {
    padding-bottom:0.5rem;
    margin-bottom:0.5rem;
    line-height:1.2;
  }

  .thead {
    font-weight:bold;
  }

  .trow {
    display:flex;
    font-size:0.8rem;
    color:$black;

    &:nth-of-type(n+5) {
      display:none;
    }

    &:not(:last-of-type) {
      border-bottom:1px dotted $medium-gray;
    }

    .tcell {
      padding:0.25rem 0 0.15rem;
      line-height:1;
    }

    >.tcell:first-of-type {
      flex:1 1 auto;
    }

    >.tcell:last-of-type {
      flex:0 0 auto;
    }

    @include breakpoint(large) {
      &:nth-of-type(n+5) {
        display:flex;
      }
    }
  }

	.country {
		vertical-align:middle;

		img {
			margin-right:0.25rem;
			vertical-align:middle;

			&[src$="/.svg"] {
				display:none;
			}
		}

		span {
			vertical-align:middle;
		}
	}
}