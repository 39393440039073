@import '~application/src/scss/sections/learn-more';

.upper-footer {
  background-color: $light-gray;

  a {
    color: #333;
    font-weight: bold;

    i {
      font-size: 1.5em;
    }
  }

  .footer-inner {
    margin: 2.5rem auto 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include xy-grid-container();
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;

    li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }
    }

    a {
      line-height: 1;
      text-decoration: none;
      display: block;
    }
  }

  .footer-cell {
    &:first-of-type {
      flex: 1 1 100%;
      order: 2;

      @include breakpoint(medium) {
        flex: 1;
        order: 1;

        li {
          width: auto;
        }
      }
    }

    &:last-of-type {
      flex: 1 1 100%;
      order: 1;

      @include breakpoint(medium) {
        flex: 0 0 auto;
        order: 2;

        li {
          width: auto;
        }
      }
    }
  }
}

.main-footer {
  background-color: $primary-color;
  color: #fff;

  .footer-inner {
    margin: auto;
    padding: 2rem 1.5rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @include xy-grid-container();
  }

  .footer-cell {
    flex: 1 1 100%;
    margin-bottom: 1.5rem;

    p {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .logo-hk {
      max-width: 80%;
      display: block;
    }

    &:first-child {
      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }

    @include breakpoint(490px) {
      flex: 1 1 50%;
    }

    @include breakpoint(large) {
      flex: 1;
    }
  }

  ul {
    line-height: 1.4rem;
    margin: 0 1rem 0 0;
    padding: 0;

    &.no-bullet {
      list-style: none;
    }
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#back-to-top {
  position: fixed;
  z-index: 9;
  display: none;
  bottom: -6rem;
  right: -6rem;
  width: 4rem;
  height: 4rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.75rem;
  border: 1px solid #fff;
  border-radius: 4rem;
  opacity: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  background-color: $primary-color;
  border-radius: 4rem;

  &:after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    transform: rotate(-45deg);
    position: relative;
    top: 1.2rem;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
  }

  &.visible {
    bottom: 6rem;
    right: 6rem;
    opacity: 0.85;

    @include breakpoint(1380px) {
      right: 1.5rem
    }

    @include breakpoint(1540px) {
      right: 6rem;
    }
  }

  &:hover {
    opacity: 1;
  }

  @include breakpoint(1380px) {
    display: block;
  }
}

.lower-footer {
  background-color: darken($primary-color, 10%);
  color: #fff;

  .footer-inner {
    margin: auto;
    font-size: 0.9rem;
    padding: 0.5rem 1rem 3.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include xy-grid-container();
  }

  .footer-cell {
    flex: 1 1 100%;

    @include breakpoint(640px) {
      &:first-of-type {
        flex: 0 0 auto;
      }

      &:last-of-type {
        flex: 1;
        text-align: right;
      }
    }
  }
}