@import 'uic-settings';

.uic-two-list-filter {
  display: flex;
  flex-wrap: wrap;

  .uic-available-header,
  .uic-chosen-header {
    flex: 1 1 100%;
    margin-bottom: 0.35rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    input {
      flex: 0 1 auto;
      width: 100%;
      max-width: 16rem;
      margin: 0;
      border-color: #ccc;
    }

    a {
      margin-left: 1.5rem;
      flex: 0 0 auto;
      color: $two-list-link-color;
    }
  }

  .uic-available-header {
    order: 1;

    a {
      .uic-add-all-label {
        display: inline;
      }

      .uic-add-visible-label {
        display: none;
      }
    }
  }

  .uic-chosen-header {
    order: 5;

    a {
      .uic-remove-all-label {
        display: inline;
      }

      .uic-remove-visible-label {
        display: none;
      }
    }
  }

  .uic-available-header.uic-filtered a {
    .uic-add-all-label {
      display: none;
    }

    .uic-add-visible-label {
      display: inline;
    }
  }

  .uic-chosen-header.uic-filtered a {
    .uic-remove-all-label {
      display: none;
    }

    .uic-remove-visible-label {
      display: inline;
    }
  }

  .uic-column-available,
  .uic-column-chosen {
    flex: 1 1 100%;
    border: 1px solid #ccc;
    background-color: #fff;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: $two-list-size * 1.5rem;
      overflow-x: hidden;
      @include overflowScrollY();

      li {
        line-height: 1.5rem;
        padding: 0 0.35rem*1.25;
        cursor: pointer;
        user-select: none;
        transition: background-color 150ms ease-in-out;

        &:hover {
          background-color: $two-list-link-hover-color;
        }

        &.uic-selected,
        &.uic-preselected {
          background-color: $two-list-selected-backgroundcolor;
          color: #fff;

          &:hover {
            background-color: darken($two-list-selected-backgroundcolor, 5%);
          }
        }

        &.uic-invisible {
          display: none !important;
        }
      }
    }
  }

  .uic-column-available {
    order: 2;
  }

  .uic-column-chosen {
    order: 6;
  }

  .uic-column-nav {
    flex: 1 1 100%;
    order: 4;
    text-align: center;
    padding: 0.25rem 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 1rem;
      }
    }

    button {
      position: relative;
      padding: 1rem 0.75rem;
      line-height: 1.5rem;
      text-align: center;
      outline: 0;
      box-shadow: inset 0 0 0 1px #aaa;
      color: #aaa;
      transition: all 150ms ease-in-out;
      transform: rotate(90deg);
      background-color: #fff;

      &.uic-active {
        box-shadow: none;
        color: #fff;
        cursor: pointer;
        background-color: $two-list-active-button-background-color;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  .uic-available-footer,
  .uic-chosen-footer {
    flex: 1 1 100%;
    order: 3;
    display: flex;
    margin-top: 0.35rem;
    font-size: 0.8rem;
    color: #999;

    >div {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: bold;
        color: #666;
      }

      div:first-of-type {
        flex: 1 1 100%;
      }

      div:last-of-type {
        flex: 0 0 auto;
      }
    }

    >div:nth-of-type(2) {
      margin-left: 50px;
    }
  }

  .uic-chosen-footer {
    order: 7;
  }
}

@media (min-width:$two-list-breakpoint-width) {
  .uic-two-list-filter {
    .uic-available-header {
      flex: 1 1 45%;
      order: 1;
    }

    .uic-chosen-header {
      flex: 1 1 45%;
      order: 2;
      margin-left: 50px;
    }

    .uic-column-available {
      flex: 1 1 45%;
      order: 3;
    }

    .uic-column-nav {
      flex: 0 0 50px;
      order: 4;
      padding: 0 0.25rem;
      align-self: center;

      ul {
        li {
          margin: 0;
        }
      }

      button {
        font-size: 1rem;
        padding: 0 0.5rem;
        transform: rotate(0);

        &.uic-add {
          margin-bottom: 1.5rem;
        }
      }
    }

    .uic-column-chosen {
      flex: 1 1 45%;
      order: 5;
    }

    .uic-available-footer {
      flex: 1 1 45%;
      order: 6;
    }

    .uic-chosen-footer {
      flex: 1 1 45%;
      order: 7;
      margin-left: 50px;
    }
  }
}