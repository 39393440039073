.component.related-categories {
  .component-item {
    padding-bottom:0.5rem;
    font-size:0.8rem;

    &:not(:last-of-type) {
      border-bottom:1px dotted $medium-gray;
      margin-bottom:0.5rem;
    }
  }
}