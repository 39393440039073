.component.recent-news {
  a.component-item {
    display:flex;
    font-size:0.8rem;
    font-weight:bold;
    line-height:1.1;
    padding-bottom:.5rem;

    &:not(:last-of-type) {
      border-bottom:1px solid #cacaca;
      margin-bottom:1rem;
    }

    >div:first-of-type {
      padding-right:0.5rem;
    }

    .news-date {
      font-weight:normal;
      font-size:0.7rem;
      margin-top:0.25rem;
    }
  }

  .news-image-container img {
    max-width:40px;
  }
}