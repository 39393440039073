.component.price-charts {
  .sub-headline {
    font-weight:bold;
    padding-bottom:0.5rem;
    margin-bottom:0.5rem;
    border-bottom:1px dotted $black;
  }

  .component-item {
    font-size:0.8rem;

    &:not(:last-of-type) {
      padding-bottom:0.25rem;
      border-bottom:1px dotted $medium-gray;
      margin-bottom:0.25rem;
    }

    .country {
      vertical-align:middle;

      img {
        margin-right:0.15em;
        vertical-align:middle;

        &[src$="/.svg"] {
          display:none;
        }
      }

      span {
        vertical-align:middle;
      }
    }
  }
}