.component.price-information {
  .sub-headline {
    font-weight:bold;
    padding-bottom:0.5rem;
    margin-bottom:0.5rem;
    border-bottom:1px dotted $black;
  }

  .component-item {
    padding-right:25px;
    font-size:0.8rem;
    position:relative;

    &:not(:last-of-type) {
      padding-bottom:0.5rem;
      border-bottom:1px solid $medium-gray;
      margin-bottom:0.5rem;
    }
  }

  .item-price-change-indicator {
    width:22px;
    height:22px;
    background-color:#fff;
    border-radius:22px;
    position:absolute;
    bottom:0.5rem;
    right:0;
    text-align:center;

    i {
      position:relative;
      top:1px;
      font-size:14px;
      line-height:19px;

      &.fa-arrow-up {
        color:$success-color;
      }

      &.fa-arrow-down {
        color:$alert-color;
      }

      &.fa-arrow-right {
        color:#838ac5;
      }
    }
  }
}