.component {
  background-color: $white;
  box-shadow: inset 0 0 0 1px $light-gray;
  margin-bottom: 0.75rem;

  .component-header {
    padding: 0.75rem 1rem;
    display: flex;
    font-weight: bold;
    background-color: $light-gray;
    line-height: 1.25rem;
    min-height: 3rem;

    &.align-flex-center {
      align-items: center;
    }

    a {
      vertical-align: middle;
    }

    i {
      vertical-align: middle;
      margin-right: 0.5rem;
      margin-top: 0.15rem;
    }

    span {
      vertical-align: middle;
    }
  }

  .component-body {
    padding: 0.75rem 1rem;
    position: relative;
    min-height: 4rem;

    p {
      font-size: 0.9rem;
    }
  }

  a.component-item {
    display: block;
    color: $body-font-color;

    &:hover {
      color: lighten($body-font-color, 20%);
    }
  }

  .learn-more {
    display: block;
    color: #fff;
    @include button-style($mundus-agri-color, scale-color($mundus-agri-color, $lightness: -15%), #fff);
    padding: 0.15rem 0.5rem;
    margin-top: 1rem;
    text-align: center;
  }
}