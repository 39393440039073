.component.attached-files {
  .component-body {
    padding:0;
    font-size:.8rem;
    margin-left:2px;
  }

  .thead {
    font-weight:bold;
  }

  .trow {
    display:flex;
    font-size:0.75rem;

    >.tcell:first-of-type {
      flex:1;
    }

    >.tcell:last-of-type {
      flex:0 0 50px;
    }
  }

  .tcell {
    margin-right:2px;
    margin-bottom:2px;
    background-color:#fff;
    padding:0.5rem 0.85rem;
  }

  a {
    text-decoration:underline;
  }
}