.component.social-media-buttons {
  background-color:$light-gray;
  margin-bottom:1rem;

  .component-body {
    padding:1rem 1rem 0;
    display:flex;
    flex-wrap:wrap;
  }

  .component-item {
    margin-bottom:0.5rem;
    flex:0 0 50%;
  }
}