.component.crop-sizes {
	.sub-headline {
		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;
		line-height: 1.2;
	}

	.thead {
		font-weight: bold;
	}

	.trow {
		display: flex;
		font-size: 0.8rem;
		color: $black;

		&:nth-of-type(n+5) {
			display: none;
		}

		&:not(:last-of-type) {
			border-bottom: 1px dotted $medium-gray;
		}

		.tcell {
			padding: 0.25rem 0 0.15rem;
			line-height: 1;
			flex: 1 1 100%;
			white-space: nowrap;
			overflow: hidden;
		}

		>.tcell:nth-of-type(2) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			max-width: 50px;
		}

		>.tcell:last-of-type {
			max-width: 100px;
		}

		@include breakpoint(large) {
			&:nth-of-type(n+5) {
				display: flex;
			}
		}
	}

	.component-body {
		padding: 0rem 1rem 1rem 1rem;
	}

	.country {
		display: flex;
    overflow: hidden;
    align-items: center;

		img {
			margin-right: 0.5rem;
			width: 20px;
			min-width: 20px;

			&[src$="/.svg"] {
				display: none;
			}
		}

		span {
			vertical-align: middle;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	@for $n from 5 to 11 {
		@media (max-width: 1178px) {
			.row:nth-child(#{$n}) {
				display: none;
			}
		}
	}
}