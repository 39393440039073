$padding:0.44em; // only 1 value allowed
$border-radius:0;
//$border-active-color:#aaa;
$menu-item-padding:0.5em;
$message-padding:0.5em;
$caret-down-color:#666;
$message-color:#ccc;
$breakpointSmallWidth:480px;
$breakpointLargeWidth:800px;

.uic-multi-filter-input {
  * {
    box-sizing: border-box;
  }

  overflow:hidden;
  display:flex;
  flex-direction:column;
  font-size: 0.9rem;

  .uic-column {
    flex: 1 1 0px;
  }

  &.uic-active .uic-inner {
    border-color: #8a8a8a;
  }

  .uic-inner {
    cursor: text;
    width: 100%;
    background-color: #fff;
    border: 1px solid $medium-gray;
    line-height: 1em;
    padding: 0.25rem 0.5rem;
    display: inline-block;
    position: relative;
    text-align: left;
    border-radius: $border-radius;
    transition: border-color 250ms ease-in-out;

    .uic-selection {
      cursor: text;

      .uic-input {
        min-width: 1px;
        width: auto;
        display: inline-block;
        border: 0;
        line-height: 100%;
        max-width: 100%;
        outline: 0;
        padding: $padding 0;
        margin-bottom: 0;
        vertical-align: top;
        float: none;
      }

      .uic-selected {
        user-select: none;
        vertical-align: top;
        white-space: normal;
        padding: 0.25em 0.75em;
        margin: 0.15rem 0.25rem 0.15rem 0;
        box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
        font-weight: bold;
        line-height: 1;
        text-transform: none;
        display: inline-block;
        background-color: #e8e8e8;
        background-image: none;
        color: rgba(0, 0, 0, .6);
        border: 0 solid transparent;
        border-radius: 0.25rem;
        cursor: default;

        .uic-remove {
          margin-left: 0.25em;
          display: inline;
          cursor: pointer;
          position: relative;
          display: inline-block;
          width: 0.65em;
          height: 0.65em;
          overflow: hidden;

          &::before,
          &::after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: #666;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .uic-default-text {
      position: absolute;
      display: inline-block;
      user-select: none;
      color: $medium-gray;
      left: 0;
      top: 0;
      line-height: 1;
      margin: 0.5rem;
    }

    .uic-message {
      display: none;
      user-select: none;
    }
  }

  .uic-add-wrapper {
    flex: 1 1 100%;

    .uic-add {
      width: 100%;
      cursor: default;

      &.uic-has-input {
        cursor: pointer;
      }
    }
  }
}

@media (min-width:$breakpointSmallWidth) {
  .uic-multi-filter-input {
    .uic-add-wrapper {
      flex: 1 1 0px;
      text-align: right;

      .uic-add {
        width: auto;
      }
    }
  }
}

@media (min-width:$breakpointLargeWidth) {
  .uic-multi-filter-input {
    flex-direction: row;

    .uic-column:first-of-type {
      flex: 1 1 0px;
      padding-right: 0.5em;
    }

    .uic-add-wrapper {
      flex: 0 0 auto;
    }
  }
}